import React, {useMemo, useRef} from "react";
import {AgGridReact} from "ag-grid-react";
import {LabelFilter} from "./LabelFilter";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "./AgTable.css"
import {useGlobalContext} from "../../GlobalContext";

export function AgTable({
                            id, setGridApi, columnDefs, rowData,
                            onSelectionChanged, onRowDoubleClicked, onModelUpdated
                        }) {
    const {data: settings, loading: settingsLoading, setData} = useGlobalContext()[`table-${id}`];
    const gridApi = useRef();
    const saveState = () => {
        const columnSettings = gridApi.current.getColumnState()
            .map(colState => {
                return {
                    colId: colState.colId,
                    width: colState.width,
                    hide: colState.hide,
                };
            });
        fetch(`/api/user/tables/${id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(columnSettings)
        }).then(() => {
            console.log("Saved column settings");
            // The updated settings are direclty also put into the cached data to avoid the delay
            // when loading them again from the backend
            setData(columnSettings);
        })
    };

    const columnsWithSettings = useMemo(() => {
        const columnDefsMap = new Map();
        settings.forEach(d => columnDefsMap.set(d.colId, d));
        return columnDefs.map(colDef => {
            let s = columnDefsMap.get(colDef.field);
            return {
                valueFormatter: params => {
                    return params.value;
                },
                ...colDef,
                ...s
            };
        });
    }, [settings]);

    let defaultColDef = useMemo(() => ({
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
    }), []);
    return <div
        className={["ag-theme-balham"].join(" ")}
    >
        {settings && !settingsLoading && <AgGridReact
            gridOptions={{
                rowSelection: "multiple",
                enableCellTextSelection: true,
                suppressCellFocus: true,
                suppressRowClickSelection: true,
                suppressDragLeaveHidesColumns: true,
                components: {
                    groupsFilter: LabelFilter
                },
                onGridReady: params => {
                    gridApi.current = params.api;
                    setGridApi(params.api);
                },
                context: {},
                domLayout: "autoHeight"
            }}
            defaultColDef={defaultColDef}
            columnDefs={columnsWithSettings}
            rowData={rowData}
            onSelectionChanged={onSelectionChanged}
            onRowDoubleClicked={onRowDoubleClicked}
            onCellClicked={event => {
                if (event.column.colId === "_id") {
                    // Workaround to ignore clicks on _id column.
                    // This column is used to display the Edit-Button in the watchlist and should not trigger
                    // a cell click.
                    return;
                }
                onRowDoubleClicked?.(event);
            }}
            onDragStopped={saveState}
            onColumnVisible={saveState}
            onColumnResized={event => {
                if (event.finished) {
                    saveState();
                }
            }}
            onModelUpdated={onModelUpdated}
        />}
    </div>
}